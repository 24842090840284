<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 card p-1">
        <div>
          <div class="h2" style="color: #558cef">กลุ่มวัสดุ</div>
          <hr />

          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1>เพิ่มกลุ่มวัสดุ</b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                />
                <div>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>
                
                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </b-dropdown-item>
                   
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
             
              :line-numbers="true"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- {{ props.row }} -->
                <span v-if="props.column.field === 'edit'" class="text-nowrap">
                  <b-button
                    variant="outline-info"
                    v-b-modal="`modal-edit${props.index}`"
                  >
                    <feather-icon icon="Edit3Icon" size="16" class="mx-1" />
                  </b-button>
                  <b-modal
                    :id="`modal-edit${props.index}`"
                    cancel-variant="outline-secondary"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    title="กลุ่มวัสดุ"
                    size=""
                    @ok="edit_data(props.row)"
                    no-close-on-backdrop
                  >
                    <b-form>
                      <div>
                        <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label="รหัสกลุ่มวัสดุ:"
                        >
                          <template v-slot:label>
                            รหัสกลุ่มวัสดุ <span class="text-danger"> *</span>
                          </template>
                          <b-form-input
                            disabled
                            id="basicInput"
                            v-model="props.row.m_gid"
                            placeholder="รหัสกลุ่มวัสดุ"
                          />
                        </b-form-group>
                        <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label="ชื่อ:"
                        >
                          <template v-slot:label>
                            ชื่อกลุ่มวัสดุ <span class="text-danger"> *</span>
                          </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.m_gname"
                            placeholder="ชื่อกลุ่มวัสดุ"
                          />
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </span>
                <span
                  v-else-if="props.column.field === 'vie'"
                  class="text-nowrap"
                >
                  <b-button variant="outline-info" @click="dateField(props)">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="16"
                      class="mx-1"
                    />
                  </b-button>
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => handlePageChange(value)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- //model -->
      <div>
        <b-modal
          id="modal-1"
          cancel-variant="outline-secondary"
          ok-title="บันทึก"
          cancel-title="ยกเลิก"
          title="กลุ่มวัสดุ"
          size=""
          @ok="getdata"
          no-close-on-backdrop
        >
          <b-form>
            <div>
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="รหัสกลุ่มวัสดุ:"
              >
                <template v-slot:label>
                  รหัสกลุ่มวัสดุ <span class="text-danger"> *</span>
                </template>
                <b-form-input
                  id="basicInput"
                  v-model="m_gid"
                  placeholder="รหัสกลุ่มวัสดุ"
                />
              </b-form-group>
              <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อ:">
                <template v-slot:label>
                  ชื่อกลุ่มวัสดุ <span class="text-danger"> *</span>
                </template>
                <b-form-input
                  id="basicInput"
                  v-model="m_gname"
                  placeholder="ชื่อกลุ่มวัสดุ"
                />
              </b-form-group>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormInput, BRow, BCol } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';

export default {
  components: {
    VueGoodTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormGroup,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
  },
  data() {
    return {
      show: false,
      m_gid: '',
      m_gname: '',
      pageLength: 50,
      total: '',
      columns: [
        {
          label: 'รหัสกลุ่มวัสดุ',
          field: 'm_gid',
        },
        {
          label: 'ชื่อกลุ่มวัสดุ',
          field: 'm_gname',
        },
        {
          label: 'แก้ไข',
          field: 'edit',
          width: '5%',
        },
        {
          label: '',
          field: 'vie',
          width: '5%',
        },
      ],
      rows: [],
      searchTerm: '',
      striped: false,
      bordered: true,
      borderless: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: true,
      footClone: true,
      headVariant: null,
      tableVariant: 'none',
      noCollapse: false,
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    this.show = true;
    const { access_token } = await this.access_token();
    const url = `${API}materialGroups?_page=1&_limit=100&_sort=-1`;
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);
    this.rows = res.data.message.data;
    this.total = res.data.message.total;
    this.show = false;
  },
  methods: {
    dateField: async function (props) {
      this.$router.push({
        name: 'inpusduhlak',
        params: {
          rowData: props.row, // or anything you want
        },
      });
    },
    async edit_data(data_edit) {
      const { access_token } = await this.access_token();
      const data = {
        m_gname: data_edit.m_gname,
      };

      axios
        .put(API + 'materialGroups/' + data_edit.id.toString(), data, {
          headers: {
            Authorization: access_token,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status == 200) {
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'บันทึกข้อมูล',
              showConfirmButton: false,
              timer: 1500,
            });
            this.handlePageChange();
          } else {
            Swal.fire({
              position: 'top-center',
              icon: 'error',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'กลุ่มวัสดุ',
        columns: [
          {
            title: 'กลุ่มวัสดุ',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'รหัสกลุ่มวัสดุ',
                field: 'm_gid',
              },
              {
                title: 'ชื่อกลุ่มวัสดุ',
                field: 'm_gname',
              },
            ],
          },
        ],
      });
    },
    async getdata() {
      this.m_gid = this.m_gid.replace(' ', '');
      const QC = this.rows.find(({ m_gid }) => m_gid === this.m_gid);
      if (QC) {
        // console.log('มีข้อมูลซ้ำ');
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          title: `ข้อมูลซ้ำ ${QC.m_gid}-${QC.m_gname}`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        const { access_token } = await this.access_token();
        const data = {
          m_gid: this.m_gid,
          m_gname: this.m_gname,
        };
        axios
          .post(API + 'materialGroups', data, {
            headers: {
              Authorization: access_token,
            },
          })
          .then((response) => {
            // console.log(response);
            if (response.data.status == 200) {
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'บันทึกข้อมูล',
                showConfirmButton: false,
                timer: 1500,
              });
              this.handlePageChange();
            } else {
              Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              position: 'top-center',
              icon: 'error',
              title: error.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    async handlePageChange(currentPage) {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}materialGroups?_page=${currentPage}&_limit=${this.pageLength}&_sort=1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false;
    },

    async access_token() {
      //get access_token
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization:
              localStorage.getItem('storedData') &&
              JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
